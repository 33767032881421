<template>
  <div class="columns is-gapless is-centered is-mobile px-4 pt-6">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen is-4-fullhd">
      <div class="container has-text-centered pt-6">
        <div v-if="!emailSubmitted">
          <div class="is-size-5 has-text-weight-semibold">
            To start, enter your email
          </div>
          <div class="has-text-grey mb-2">We will send an email with your unique start link.</div>
          <input class="input is-medium" type="email" v-model="email" placeholder="Enter your email">
          <button
            class="button is-fullwidth has-text-weight-semibold is-primary is-medium mt-3"
            @click="submitEmail"
            :disabled="!email"
          >
            Submit
          </button>
          <div v-if="errorMessage" class="has-text-danger mt-2">{{ errorMessage }}</div>
        </div>
        <div v-else>
          <div class="is-size-5 has-text-weight-semibold mb-1">
            Check your email for a unique link to start. Check your spam folder too!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'QrStart',
  data () {
    return {
      gameCode: this.$route.params.gameCode,
      accessCode: this.$route.params.accessCode,
      email: '',
      emailSubmitted: false,
      errorMessage: ''
    }
  },
  computed: {
    userName() {
      return this.$store.state.userName
    },
    teamCode() {
      return this.$store.state.teamCode
    },
    localStorageGameCode() {
      return this.$store.state.gameCode
    }
  },
  methods: {
    submitEmail() {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
      if (emailRegex.test(this.email.trim().toLowerCase())) {
        this.emailSubmitted = true
        this.errorMessage = ''
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        masterFunction({
          methodName: 'create-team-qr-start',
          email: this.email.trim().toLowerCase(),
          gameCode: this.gameCode
        }).then(res => {
          // Email submission successful
        }).catch(err => {
          this.emailSubmitted = false
          this.errorMessage = err.message
        })
      } else {
        this.errorMessage = 'Invalid email. Please try again.'
      }
    }
  },
  mounted() {
    console.log('this.localStorageGameCode', this.localStorageGameCode)
    if (this.teamCode && this.userName && this.gameCode === this.localStorageGameCode) {
      localStorage.setItem('playToursQrStartAccessCode', this.accessCode)
      this.$router.push({ name: 'Lobby', params: { teamCode: this.teamCode } })
    }
  }
}
</script>
